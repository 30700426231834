import React from "react";
import styles from "./scss/PrivacyPolicy.module.scss";
import { Context } from "./index";

export const PrivacyPolicy: React.FC<{ lang: "en" | "es" }> = props => {
  const context = React.useContext(Context);

  React.useEffect(() => {
    context.SetLang(props.lang);
  });

  return (
    <div className={`privacyPolicy`}>
      <div className={styles.privacyPolicyContainer}>
        {context.Lang === "en" ? (
          <div>
            <h2>Privacy Notice</h2>
            <p>Revised August 19, 2021</p>
            <p>At Predictive Strategies, Inc., (“we,” “us,” or “our”) protecting your privacy is fundamental to our business.  We are committed to safeguarding your information and to explaining how we collect, use, and disclose your Personal Information related to your use of safeairbags.com and predictivestrategies.com (the “Websites”) and your interaction(s) with us in general.  This Privacy Notice (the “Notice”) is intended to comply with all applicable data-privacy laws, including but not limited to the California Consumer Privacy Act of 2018 (the “CCPA”).</p>
            <h2>Definitions</h2>
            <p><em>Personal Information</em> means information about an individual who can be identified from use of that information (or from that information when combined with other information in our possession or likely to come into our possession), including information that identifies, relates to, describes, is capable of being associated with, or could reasonably be linked, directly or indirectly, with a particular consumer or household.  Types of personal information typically include names, physical addresses, mailing addresses, social security numbers, email addresses, phone numbers, bank account numbers and driver’s license numbers.  But Personal Information may also include other information that can reasonably link to a particular person, such as internet protocol (IP) addresses, unique device identification numbers, employment information, and internet activity.  Personal Information does not include publicly available information from government records; de-identified or aggregated consumer information; or for purposes of this Notice, information specifically excluded from or by applicable data-privacy laws, such as the Health Insurance Portability and Accountability Act of 1996 (HIPAA), the Fair Credit Reporting Act (FRCA), the Gramm-Leach-Bliley Act (GLBA), and the Driver’s Privacy Protection Act of 1994.</p>
            <p><em>Usage Data</em> is data collected automatically that is either generated by your use of the Website or from the Website infrastructure itself (for example, the duration of your visit to a web page).</p>
            <p><em>Cookies</em> and <em>Pixels</em> are small data files that are placed on your computer or mobile device when you visit a website (cookies) and snippets of code that track use behavior on a website (pixels). Cookies and Pixels are used, for example, to make websites work or to make them work more efficiently.  Cookies and Pixels can also provide reporting information to track how a user interacts with a website.</p>
            <h2>Collection and Use of Personal Information</h2>
            <p>When you visit our Websites or interact with us or with our third parties performing services on our behalf, you may be asked to provide certain Personal Information.  Other Personal Information may automatically be collected by our Websites.  Further, we collect information from other sources and combine that with information we collect directly.  We do, however, limit our collection of Personal Information to information that we may use to perform the services described on our Websites or through other channels of engagement, which includes providing you with information related to the Takata Airbag Recall campaign and any promotions related thereto.  We will not collect additional categories of Personal Information or use the Personal Information we collected for materially different, unrelated, or incompatible purposes without providing you notice.  We currently collect the following categories of Personal Information from the sources and for the purposes indicated below:</p>
            <ul>
              <li>Personal Identifiers, including your real name, your physical address, your email address, your IP address, and demographic information.  We collect this information (a) directly from you either (i) through your use of our Websites or (ii) through information you submit to our third-party service providers, or (b) from consumer data resellers.  We use this information to contact you for the purposes of engaging in the Takata Airbag Recall campaign or to provide you with other, related information that you have requested.</li>
              <li>Commercial Information, including the VIN and make and model of your vehicle.  We collect this information directly from you through your use of our Websites, from consumer data resellers, and from automobile original equipment manufacturers.  We use this information to determine whether your vehicle is subject to the Takata Airbag Recall.</li>
              <li>Internet Activity, including your interaction with the Websites, the type of browser you use, access times, and pages viewed.  We collect this information (a) directly from you through your use of our Websites, generally through use of Cookies and Pixels as discussed further below, or (b) through your interaction with our third-party service providers, which may include portals setup and managed by our third-party service providers.  We use this information to improve the Websites and present their contents to you and to improve our promotions and the effectiveness of the Takata Airbag Recall campaign.</li>
              <li>Inferences Drawn from such information, including your preferences, characteristics, psychological trends, predispositions, and behavior that may help us improve the effectiveness of our safety-awareness campaign.  We collect this information from consumer data resellers.  We use this information to improve the effectiveness of the Takata Airbag Recall campaign.</li>
            </ul>
            <h2>Cookies and Pixels</h2>
            <p>We use cookies and pixels to track your activity on the Websites and our third-party service providers’ websites and to hold certain information.  You can instruct your browser to refuse all cookies and pixels or to indicate when a cookie or pixel is being used.  However, if you do not accept cookies and pixels for the Websites, you may not be able to use some portions of the Websites.</p>
            <h2>Non-Personal Information</h2>
            <p>This Notice does not restrict our collection, use, or disclosure of any aggregated information or information that does not identify, or cannot be reasonably linked to, any individual.</p>
            <h2>Disclosure and Sharing of Personal Information with Service Providers</h2>
            <p>We may disclose Personal Information we collect from you or from the categories of sources described above: (1) to our subsidiaries and affiliates; (2) to contractors, service providers, and other third parties we use to support our business; (3) to fulfill the purpose for which you provide it; (4) to a buyer or other successor in the event of a sale or transfer of some or all of our business or assets; (5) for any other purpose disclosed by us when you provide the information; (6) to comply with any court order, law, or legal process, including responding to any government or regulatory request; (7) if we believe disclosure is necessary or appropriate to protect the rights, property, or safety of us, our clients, or others; or (8) for additional purposes with your consent. We do not sell or otherwise share personal information with third parties for direct marketing purposes.  And when we do share your information with our service providers, they are contractually prohibited from retaining, using, or disclosing your Personal Information for any purpose other than that specified in the contract.</p>
            <p>To provide you with the Websites services, for example, to provide you with recall information related to your vehicle, we must communicate with our third-party providers.  These third parties may also have privacy policies that differ from ours.  For example, they may collect personal information about your online activities over time and across different websites and other online services, and they may use this information to provide you with interest-based (behavioral) advertising or other targeted content.  We do not control these third-parties or their privacy practices, and this Notice does not apply to any third-party website or service you may access through our website.  If you have any questions about a third-party provider’s privacy policies or advertising, you should contact the responsible provider directly.</p>
            <p>The information we collect is currently shared with the following categories of providers for the purposes set forth below:</p>
            <ul>
              <li>Personal Identifiers, including your real name, your physical address, your email address, your IP address, and demographic information.  We share this information with data analytics providers, our call center partners, our mailing partners, and our promotional contest partners.  This information is shared so that we may confirm and append the data we collect, to improve the quality of our safety-awareness campaign, to effectuate the Takata Airbag Recall campaign, and to provide you with the results of any promotional contests that you may have entered.</li>
              <li>Commercial Information, including the VIN and make and model of your vehicle.  We share this information with our vehicle data providers so that we may provide you with the Websites’ services.</li>
              <li>Internet Activity, including your interaction with the Websites, the type of browser you use, access times, and pages viewed.  We share this information with our website analytics providers so that we may improve the Websites and present their contents to you.</li>
              <li>Inferences Drawn from such information, including your preferences, characteristics, psychological trends, predispositions, and behavior that may help us improve the effectiveness of our safety-awareness campaign.  We share this information with our data analytics providers to confirm and append the data we collect to improve the quality of our safety-awareness campaign.</li>
            </ul>
            <h2>Retention of Information</h2>
            <p>We will retain your Personal Information only for as long as is necessary for the purposes set forth herein.  However, we may retain and use your Personal Information to the extent necessary to comply with our legal obligations (for example, if we are required to retain your data to comply with applicable laws), resolve disputes, and enforce our legal agreements and policies.</p>
            <p>We will retain your Usage Data for internal analysis purposes. Usage Data is generally retained for approximately 24 months, except when this data is used to strengthen the security or to improve the functionality of our Websites or if we are legally obligated to retain this data for longer time periods.</p>
            <h2>Data Processing</h2>
            <p>Your information, including Personal Information, may be transferred to — and maintained on — computers located outside of your state, province, country, or other governmental jurisdiction, where data protection laws may differ than those from your jurisdiction.</p>
            <p>If you are located outside of the United States and choose to use the Website, please note that we transfer the data, including Personal Data, to the United States and process it there.  Your consent to this Privacy Notice followed by your use of the Website represents your agreement to that transfer.</p>
            <h2>Data Security</h2>
            <p>We have implemented measures designed to secure your information from accidental loss and from unauthorized access and disclosure. The use of, and access to, your Personal Information by us is restricted to employees and contractors who need to know that information to provide services to you. We maintain physical, electronic, and procedural safeguards to limit access to your Personal Information.  Unfortunately, transmission of information via the Internet is not completely secure. Although we do our best to protect all information submitted to our Website, we cannot guarantee the security of any such information.  Any electronic transmission of information, including your Vehicle Identification Number, is at your own risk.</p>
            <h2>Changes to our Privacy Notice</h2>
            <p>We will post any changes we make to this Privacy Notice on our website. If we make material changes to how we treat Personal Information we collect from you, we will notify you on our website homepage. The date this Privacy Novice was last revised is identified above.</p>
            <h2>California Consumer Rights and Choices</h2>
            <p>The CCPA provides consumers (California residents) with specific rights regarding their Personal Information. If you are a consumer under the CCPA, this section describes your rights and explains how to exercise those rights.</p>
            <h3>Access to Specific Information and Data Portability Rights</h3>
            <p>You have the right to request that we disclose certain information to you about our collection and use of your Personal Information over the past 12 months. Although that information is set forth in this Privacy Notice, once we receive and confirm your verifiable consumer request, we will disclose to you:</p>
            <ul>
              <li>The categories of personal information we collected about you;</li>
              <li>The categories of sources for the personal information we collected about you;</li>
              <li>Our business or commercial purpose for collecting or selling that personal information;</li>
              <li>The categories of third parties with whom we share that personal information;</li>
              <li>The specific pieces of personal information we collected about you;</li>
              <li>
                If we sold or disclosed your personal information for a business purpose, two separate lists disclosing:
        <ul>
                  <li>sales, identifying the personal information categories that each category of recipient purchased; and</li>
                  <li>disclosures for a business purpose, identifying the personal information categories that each category of recipient obtained.</li>
                </ul>
              </li>
            </ul>
            <h3>Deletion Request Rights</h3>
            <p>You have the right to request that we delete any of your personal information that we collected from you and retained, subject to certain exceptions. Once we receive and confirm your verifiable consumer request, we will delete (and direct our service providers to delete) your personal information from our records, unless an exception applies.</p>
            <p>We may deny your deletion request if retaining the information is necessary for us or our service providers to:</p>
            <ul>
              <li>Complete the transaction for which we collected the personal information, provide a good or service that you requested, take actions reasonably anticipated within the context of our ongoing business relationship with you, or otherwise perform our contract with you;</li>
              <li>Detect security incidents, protect against malicious, deceptive, fraudulent, or illegal activity, or prosecute those responsible for such activities;</li>
              <li>Debug products to identify and repair errors that impair existing intended functionality;</li>
              <li>Exercise free speech, ensure the right of another consumer to exercise their free speech rights, or exercise another right provided for by law;</li>
              <li>Comply with the California Electronic Communications Privacy Act (Cal. Penal Code § 1546 seq.).</li>
              <li>Engage in public or peer-reviewed scientific, historical, or statistical research in the public interest that adheres to all other applicable ethics and privacy laws, when the information's deletion may likely render impossible or seriously impair the research's achievement, if you previously provided informed consent;</li>
              <li>Enable solely internal uses that are reasonably aligned with consumer expectations based on your relationship with us;</li>
              <li>Comply with a legal obligation; or</li>
              <li>Make other internal and lawful uses of that information that are compatible with the context in which you provided it.</li>
            </ul>
            <h3 id="opt-out">Exercising Access, Data Portability, and Deletion Rights</h3>
            <p>To exercise the access, data portability, and deletion rights described above, please submit a verifiable consumer request to us by either:</p>
            <ul>
              <li>Calling us at 844-455-3089</li>
              <li>Emailing us at privacy@predictivestrategies.com</li>
            </ul>
            <p>Only you or a person registered with the California Secretary of State that you authorize to act on your behalf, may make a verifiable consumer request related to your personal information. You may also make a verifiable consumer request on behalf of your minor child.</p>
            <p>You may only make a verifiable consumer request for access or data portability twice within a 12-month period. The verifiable consumer request must:</p>
            <ul>
              <li>Provide sufficient information that allows us to reasonably verify you are the person about whom we collected personal information or an authorized representative, including at least your name and either your physical address or your email address.</li>
              <li>Describe your request with sufficient detail that allows us to properly understand, evaluate, and respond to it.</li>
            </ul>
            <p>We cannot respond to your request or provide you with personal information if we cannot verify your identity or authority to make the request and confirm the personal information relates to you.  Making a verifiable consumer request does not require you to create any sort of account with us.  We will only use personal information provided in a verifiable consumer request to verify the requestor’s identity or authority to make the request.</p>
            <h3>Response Timing and Format</h3>
            <p>Within 10 days of your request, we will confirm receipt of your request and provide you with information about how we will process your request, including our verification process and when you can expect a complete response to your request.  In any case, we will attempt to respond to a verifiable consumer request within 45 days of receipt.  If we require more time (up to 90 days), we will inform you of the reason and extension period in writing.  We will deliver our written response by mail or electronically, at your option.  Any disclosures we provide will only cover the 12-month period preceding the date of the request.  The response we provide will also explain the reasons we cannot comply with a request, if applicable.  For data portability requests, we will select a format to provide your personal information that is readily useable and should allow you to transmit the information from one entity to another entity without hindrance.</p>
            <p>We do not charge a fee to process or respond to your verifiable consumer request unless it is excessive, repetitive, or manifestly unfounded.  If we determine that the request warrants a fee, we will tell you why we made that decision and provide you with a cost estimate before completing your request.</p>
            <h3>Non-Discrimination</h3>
            <p>We will not discriminate against you for exercising any of your CCPA rights. Unless permitted by the CCPA, we will not:</p>
            <ul>
              <li>Deny you goods or services;</li>
              <li>Charge you different prices or rates for goods or services, including through granting discounts or other benefits, or imposing penalties;</li>
              <li>Provide you a different level or quality of goods or services; or</li>
              <li>Suggest that you may receive a different price or rate for goods or services or a different level or quality of goods or services.</li>
            </ul>
            <h2>Contact Information</h2>
            <p>If you have any questions or comments about this Notice, the ways in which we collect and use your personal information, your choices and rights regarding such use, or wish to exercise your rights, please contact us at:</p>
            <div>Phone: 844-455-3089</div>
            <div>Email: privacy@predictivestrategies.com </div>
            <div>Physical Address: PO Box 481, Bloomfield Hills, MI 48303</div>
          </div>
        ) : (<div>
          <h2>Aviso de Privacidad</h2>
          <p>Revisado el 19 de agosto de 2021</p>
          <p>Para Predictive Strategies, Inc., ("nosotros", "de nosotros" o "nuestro/s"), proteger su privacidad es fundamental en nuestro negocio. Nos comprometemos a salvaguardar su información y a explicarle cómo recopilamos, usamos y divulgamos su Información Personal en relación con su uso de safeairbags.com y predictivestrategies.com (los "sitios web") y con su(s) interacción(es) con nosotros en general. Este Aviso de Privacidad (el "Aviso") está destinado a cumplir con todas las leyes de privacidad de la información aplicables, incluida, entre otras, la Ley de privacidad del consumidor de California de 2018 (la ley "CCPA", por sus siglas en inglés).</p>
          <h2>Definiciones</h2>
          <p>La <em>Información Personal</em> significa información sobre un individuo que pueda ser identificado debido al uso de esa información (o con esa información en combinación con otra información en nuestro poder o que probablemente llegue a nuestro poder), incluida la información que identifique, se relacione con, describa, pueda estar asociada o razonablemente vinculada, directa o indirectamente, a un consumidor u hogar en particular. Los tipos de información personal generalmente incluyen nombres, direcciones físicas, direcciones postales, números de seguro social, direcciones de correo electrónico, números de teléfono, números de cuentas bancarias y números de licencia de conducción. Pero la información personal también puede incluir otra información que pueda vincularse razonablemente a una persona en particular, como direcciones de protocolo de Internet (IP), números de identificación de dispositivos únicos, datos laborales y actividad en Internet. La información personal no incluye información disponible públicamente en registros gubernamentales; información del consumidor anonimizada o combinada colectivamente; o para los fines de este Aviso, información específicamente excluida de o por las leyes aplicables de privacidad de la información, como la Ley de responsabilidad y portabilidad de seguros médicos de 1996 (HIPAA), la Ley de informes crediticios justos (FRCA), la Ley Gramm-Leach-Bliley (GLBA) y la Ley de Protección de la Privacidad del Conductor de 1994.</p>
          <p>Los <em>datos de uso</em> del usuario son datos recopilados automáticamente, ya sean generados por su uso del sitio web o por la propia infraestructura del sitio web (por ejemplo, la duración de su visita a una página web).</p>
          <p>Las <em>cookies</em> y los <em>píxeles</em> son pequeños ficheros de datos que se colocan en su computadora o dispositivo móvil cuando usted visita un sitio web (cookies) y fragmentos de código que rastrean el comportamiento del uso de un sitio web (píxeles). Las cookies y los píxeles se utilizan, por ejemplo, para que los sitios web funcionen o para que funcionen de manera más eficiente. Las cookies y los píxeles también pueden brindar información de reportes para rastrear cómo un usuario interactúa en un sitio web.</p>
          <h2>Recopilación y uso de información personal</h2>
          <p>Cuando visita nuestros sitios web o interactúa con nosotros o con nuestros terceros que prestan servicios en nuestro nombre, se le puede pedir que dé cierta información personal. Nuestros sitios web pueden recopilar automáticamente otra información personal. Además, recopilamos información de otras fuentes y la combinamos con la información que recopilamos directamente. Sin embargo, limitamos nuestra recopilación de información personal a la información que podemos usar para realizar los servicios descritos en nuestros sitios web o a través de otros canales de participación, lo cual incluye ofrecerle información relacionada con la campaña sobre las bolsas de aire defectuosas de Takata y cualquier promoción relacionada con la misma. No recopilaremos categorías adicionales de Información Personal ni utilizaremos la información personal que recopilamos para fines materialmente diferentes, no relacionados o incompatibles, sin antes avisarle. Actualmente recopilamos las siguientes categorías de información personal de las fuentes y con los fines que se indican a continuación:</p>
          <ul>
            <li>Identificadores personales, incluido su nombre real, su dirección física, su dirección de correo electrónico, su dirección IP y su información demográfica. Recopilamos esta información (a) directamente de usted, ya sea (i) a través de su uso de nuestros sitios web o (ii) a través de la información que envía a nuestros proveedores de servicios externos o (b) de los revendedores de datos de consumidores. Usamos esta información para comunicarnos con usted con el fin de que participe en la campaña sobre las bolsas de aire defectuosas de Takata o para brindarle otra información relacionada que haya solicitado.</li>
            <li>Información comercial, incluido el número de identificación de su vehículo (VIN) y la marca y el modelo de su vehículo. Recopilamos esta información directamente de usted a través de su uso de nuestros sitios web, de los revendedores de datos de consumidores y de los fabricantes de equipos originales de automóviles. Usamos esta información para determinar si su vehículo posee alguna bolsa de aire defectuosa Takata.</li>
            <li>Actividad de Internet, incluida su interacción con los sitios web, el tipo de navegador que utiliza, los tiempos de acceso y las páginas visitadas. Recopilamos esta información (a) directamente de usted a través de su uso de nuestros sitios web, generalmente a través del uso de cookies y píxeles como se explica más adelante o (b) a través de su interacción con nuestros proveedores de servicios externos, que pueden incluir la configuración de portales y la administración de nuestros proveedores de servicios externos (terceros). Usamos esta información para mejorar los sitios web y presentarle su contenido y para mejorar nuestras promociones y la efectividad de la campaña sobre las bolsas de aire defectuosas de Takata.</li>
            <li>Las inferencias extraídas de dicha información, incluidas sus preferencias, características, tendencias psicológicas, predisposiciones y comportamiento que puedan ayudarnos a mejorar la efectividad de nuestra campaña de concientización sobre la seguridad. Recopilamos esta información de revendedores de datos de consumidores. Usamos esta información para mejorar la efectividad de la campaña sobre las bolsas de aire defectuosas de Takata.</li>
          </ul>
          <h2>Cookies y píxeles</h2>
          <p>Usamos cookies y píxeles para rastrear su actividad en los sitios web y en los sitios web de nuestros proveedores de servicios externos y para almacenar cierta información. Puede configurar su navegador para que rechace todas las cookies y píxeles o indique cuando se estén utilizando cookies o píxeles. No obstante, si no acepta cookies y píxeles en los sitios web, es posible que no pueda utilizar algunas partes de los sitios web.</p>
          <h2>Información no personal</h2>
          <p>Este Aviso no restringe nuestra recopilación, uso o divulgación de cualquier información combinada colectivamente o de información no identificable o información que no pueda vincularse razonablemente a ningún individuo.</p>
          <h2>Divulgación y uso compartido de información personal con los proveedores del servicio</h2>
          <p>Podemos divulgar Información Personal que recopilemos de usted o de las categorías de fuentes descritas anteriormente: (1) a nuestras subsidiarias y afiliados; (2) a contratistas, proveedores de servicios y otros terceros que utilizamos para apoyar nuestro negocio; (3) para cumplir con el propósito para el cual la proporciona; (4) a un comprador u otro sucesor en caso de una venta o transferencia de una parte o de la totalidad de nuestro negocio o bienes activos; (5) para cualquier otro propósito revelado por nosotros cuando usted nos da la información; (6) para cumplir con cualquier orden judicial, ley o proceso legal, incluido responder ante cualquier solicitud gubernamental o regulatoria; (7) si consideramos que la revelación es necesaria o apropiada para proteger los derechos, la propiedad o la seguridad de nosotros, de nuestros clientes u otras partes; o (8) para otros propósitos según su consentimiento. No vendemos ni compartimos información personal con terceros con fines de marketing directo. Y cuando compartimos su información con nuestros proveedores de servicios, se les prohíbe mediante contrato retener, usar o divulgar su información personal para cualquier propósito que no sea el especificado en el contrato.</p>
          <p>Para brindarle los servicios de los Sitios web, por ejemplo, para brindarle información sobre cualquier defecto relacionado con su vehículo, debemos comunicarnos con nuestros proveedores externos. Estos terceros también pueden tener políticas de privacidad diferentes a las nuestras. Por ejemplo, pueden recopilar información personal sobre sus actividades en internet a lo largo del tiempo y en diferentes sitios web y otros servicios por internet (en línea) y pueden usar esta información para ofrecerle publicidad basada en intereses (de comportamiento) u otro contenido dirigido. No controlamos a estos terceros ni sus prácticas de privacidad y este Aviso no es aplicable a ningún sitio web o servicio de terceros al que usted pueda tener acceso mediante nuestro sitio web. Si tiene cualquier pregunta sobre las políticas de privacidad o la publicidad de un proveedor externo, deberá comunicarse directamente con el proveedor responsable.</p>
          <p>La información que recopilamos actualmente se comparte con las siguientes categorías de proveedores para los fines establecidos a continuación:</p>
          <ul>
            <li>Identificadores personales, incluido su nombre real, su dirección física, su dirección de correo electrónico, su dirección IP (dirección del protocolo de internet) e información demográfica. Compartimos esta información con los proveedores de análisis de datos, nuestros socios de centros de llamadas, nuestros socios de correo y nuestros socios de concursos promocionales. Esta información se comparte para que podamos confirmar y adjuntar los datos que recopilamos, para mejorar la calidad de nuestra campaña de concientización sobre la seguridad, para poner en efecto la campaña sobre las bolsas de aire defectuosas de Takata y para brindarle los resultados de cualquier concurso promocional en el que usted haya participado.</li>
            <li>Información comercial, incluido el número de identificación del vehículo (VIN) y la marca y modelo de su vehículo. Compartimos esta información con nuestros proveedores de datos de vehículos para poder brindarle los servicios de los sitios web.</li>
            <li>Actividad de Internet, incluida su interacción con los sitios web, el tipo de navegador que utiliza, los tiempos de acceso y las páginas visitadas. Compartimos esta información con nuestros proveedores de análisis de sitios web para poder mejorar los sitios web y presentarle a usted sus contenidos.</li>
            <li>Las inferencias extraídas de dicha información, incluidas sus preferencias, características, tendencias psicológicas, predisposiciones y comportamiento que puedan ayudarnos a mejorar la efectividad de nuestra campaña de concientización sobre la seguridad. Compartimos esta información con nuestros proveedores de análisis de datos para confirmar y adjuntar los datos que recopilamos para mejorar la calidad de nuestra campaña de concientización sobre la seguridad.</li>
          </ul>
          <h2>Retención de la información</h2>
          <p>Retendremos su información personal solo durante el tiempo que sea necesario para los fines establecidos en este documento. Sin embargo, podemos retener y usar su Información Personal en la medida necesaria para cumplir con nuestras obligaciones legales (por ejemplo, si estamos obligados a retener sus datos para cumplir con las leyes aplicables), resolver disputas y hacer cumplir nuestros acuerdos y políticas legales.</p>
          <p>Conservaremos sus Datos de Usuario para fines de análisis interno. Los datos de uso del usuario generalmente se conservan durante aproximadamente 24 meses, excepto cuando estos datos se utilizan para reforzar la seguridad o mejorar la funcionalidad de nuestros sitios web o si estamos legalmente obligados a conservar esta información durante un tiempo más prolongado.</p>
          <h2>Procesamiento de datos</h2>
          <p>Su información, incluida la Información Personal, puede transferirse a y mantenerse en computadoras ubicadas fuera de su estado, provincia, país u otra jurisdicción gubernamental, donde las leyes de protección de la información pueden diferir de las de su jurisdicción.</p>
          <p>Si se encuentra fuera de los Estados Unidos y opta por usar el sitio web, tenga en cuenta que transferimos los datos, incluidos los datos personales, a los Estados Unidos y los procesamos allí. Su consentimiento a este Aviso de Privacidad seguido de su uso del sitio web representa su acuerdo con esa transferencia.</p>
          <h2>Seguridad de datos</h2>
          <p>Hemos tomado medidas diseñadas para proteger su información ante pérdidas accidentales y acceso y divulgación no autorizados. El uso de su información personal y el acceso a la misma por nuestra parte está restringido a los empleados y contratistas que necesitan conocer esa información para brindarle servicios. Mantenemos medidas de seguridad físicas, electrónicas y de procedimiento para limitar el acceso a su información personal. Desafortunadamente, la transmisión de información a través de Internet no es completamente segura. Aunque hacemos todo lo posible para proteger toda la información enviada a nuestro sitio web, no podemos garantizar la seguridad de dicha información. Cualquier transmisión electrónica de la información, incluido el número de identificación de su vehículo, la hace bajo su propio riesgo.</p>
          <h2>Cambios a nuestro Aviso de Privacidad</h2>
          <p>Publicaremos en nuestro sitio web cualquier cambio que hagamos a este Aviso de Privacidad. Si hacemos cambios sustanciales en la forma en que manejamos la información personal que recopilamos sobre usted, se lo notificaremos en la página de inicio de nuestro sitio web. La fecha de la última revisión de este Aviso de Privacidad se indica arriba.</p>
          <h2>Derechos y opciones del consumidor de California</h2>
          <p>La Ley de Privacidad del Consumidor de California (CCPA, por sus siglas en inglés) les brinda a los consumidores (residentes de California) derechos específicos con respecto a su información personal. Si usted es un consumidor bajo CCPA, esta sección describe sus derechos y explica cómo ejercerlos.</p>
          <h3>Derechos de acceso a información específica y portabilidad de datos</h3>
          <p>Tiene derecho a solicitar que le relevemos cierta información sobre nuestra recopilación y uso de su información personal durante los últimos 12 meses. Aunque esa información se establece en este Aviso de Privacidad, una vez que recibamos y confirmemos su solicitud de consumidor verificable, le revelaremos:</p>
          <ul>
            <li>Las categorías de información personal que recopilamos sobre usted;</li>
            <li>Las categorías de fuentes de la información personal que recopilamos sobre usted;</li>
            <li>El propósito de nuestro negocio o el propósito comercial al recopilar o vender esa información personal;</li>
            <li>Las categorías de terceros con quienes compartimos esa información personal;</li>
            <li>Los datos personales específicos que recopilamos sobre usted;</li>
            <li>
              Si vendimos o divulgamos su información personal con fines comerciales, dos listas separadas que revelen:
        <ul>
                <li>las ventas, identificando las categorías de información personal que cada categoría de destinatario compró; y</li>
                <li>revelaciones con fines comerciales, identificando las categorías de información personal que obtuvo cada categoría de destinatario.</li>
              </ul>
            </li>
          </ul>
          <h3>Derechos de solicitud de eliminación de información</h3>
          <p>Tiene derecho a solicitar que eliminemos cualquier parte de su información personal que hayamos recopilado y retenido, sujeto a ciertas excepciones. Una vez que recibamos y confirmemos su solicitud de consumidor verificable, eliminaremos (y les indicaremos a nuestros proveedores de servicio que eliminen) su información personal de nuestros registros, a menos que sea aplicable alguna excepción.</p>
          <p>Podemos rechazar su solicitud de eliminación de ser necesario que nosotros o nuestros proveedores de servicios retengamos la información para:</p>
          <ul>
            <li>Completar la transacción para la cual recopilamos la información personal, brindar un producto o servicio que solicitó, tomar medidas razonablemente anticipadas dentro del contexto de nuestra relación comercial actual con usted o cumplir nuestro contrato con usted;</li>
            <li>Detectar incidentes de seguridad, proteger contra actividades maliciosas, engañosas, fraudulentas o ilegales o enjuiciar a los responsables de tales actividades;</li>
            <li>Depurar fallos en productos para identificar y reparar errores que perjudiquen la funcionalidad deseada existente;</li>
            <li>Ejercer la libertad de expresión, garantizar el derecho de otro consumidor a ejercer sus derechos de libertad de expresión o ejercer otro derecho provisto por la ley;</li>
            <li>Cumplir con la Ley de Privacidad de las Comunicaciones Electrónicas de California (Código Penal de California § 1546 seq.).</li>
            <li>Participar en investigaciones científicas, históricas o estadísticas, públicas o revisadas por otros para el interés público, que se adhieran a todas las demás leyes aplicables de ética y privacidad, cuando la eliminación de la información probablemente imposibilite o perjudique gravemente el logro de la investigación, si usted previamente dio su consentimiento informado;</li>
            <li>Permitir solamente usos internos que se correspondan razonablemente con las expectativas del consumidor en función de su relación con nosotros;</li>
            <li>Cumplir con alguna obligación legal; o</li>
            <li>Hacer otros usos internos y lícitos de esa información que sean compatibles con el contexto en el que la proporcionó.</li>
          </ul>
          <h3 id="opt-out">Ejercicio de los derechos de acceso, portabilidad y eliminación de datos</h3>
          <p>Para los ejercer los derechos de acceso, portabilidad y eliminación de datos descritos anteriormente, haga una solicitud de consumidor verificable:</p>
          <ul>
            <li>Por teléfono, llamando al 844-455-3089</li>
            <li>Por correo electrónico a privacy@predictivestrategies.com</li>
          </ul>
          <p>Solo usted o una persona que se encuentre en los registros de la Secretaría de Estado de California, a quien usted autorice para actuar en su nombre, puede realizar una solicitud de consumidor verificable relacionada con su información personal. También puede realizar una solicitud de consumidor verificable en nombre de su hijo menor de edad.</p>
          <p>Solo puede realizar una solicitud de consumidor verificable de acceso o portabilidad de datos dos veces en un período de 12 meses. La solicitud verificable del consumidor deberá:</p>
          <ul>
            <li>Ofrecer información suficiente que nos permita verificar razonablemente que usted es la persona sobre la que recopilamos información personal, o un representante autorizado, incluyendo al menos su nombre y su dirección física o su dirección de correo electrónico.</li>
            <li>Describir su solicitud con suficiente detalle que nos permita entenderla, evaluarla y responderla adecuadamente.</li>
          </ul>
          <p>No podemos responder a su solicitud ni brindarle información personal si no podemos verificar su identidad o la autoridad que realiza la solicitud y confirmar que la información personal se relaciona con usted. Hacer una solicitud de consumidor verificable no requiere que usted cree ningún tipo de cuenta con nosotros. Solo utilizaremos la información personal dada en una solicitud de consumidor verificable para verificar la identidad del solicitante o de la autoridad que realiza la solicitud.</p>
          <h3>Tiempo y formato de respuesta</h3>
          <p>Dentro de los 10 días posteriores a su solicitud, haremos acuse de recibo de la misma y le ofreceremos información sobre cómo procesaremos la solicitud, incluido nuestro proceso de verificación y cuándo esperar una respuesta completa a su solicitud. De cualquier modo, intentaremos responder a una solicitud verificable del consumidor dentro de los 45 días posteriores a recibirla. Si necesitamos más tiempo (hasta 90 días), le informaremos el motivo y el período de extensión por escrito. Le enviaremos nuestra respuesta por escrito o electrónicamente, según su elección. Cualquier revelación que hagamos solo cubrirá el período de 12 meses anterior a la fecha de la solicitud. La respuesta que le demos también explicará las razones por las que no podemos cumplir con la solicitud, si corresponde. Para las solicitudes de portabilidad de datos, seleccionaremos un formato para proporcionar su información personal que sea fácilmente utilizable y le permita transmitir la información de una entidad a otra sin obstáculos.</p>
          <p>No cobramos una tarifa por procesar o responder a su solicitud de consumidor verificable a menos que sea excesiva, repetitiva o manifiestamente infundada. Si determinamos que la solicitud justifica el pago de una tarifa, le informaremos por qué tomamos esa decisión y le daremos un costo estimado antes de procesar su solicitud.</p>
          <h3>Política de No Discriminación</h3>
          <p>No le discriminaremos por ejercer cualquiera de sus derechos a la Ley de Privacidad del Consumidor de California (CCPA). A menos que lo permita la ley CCPA, no haremos lo siguiente:</p>
          <ul>
            <li>Negarle productos o servicios;</li>
            <li>Cobrarle precios o tarifas diferentes por productos o servicios, que incluyen conceder descuentos u otros beneficios o imponer sanciones;</li>
            <li>Brindarle un nivel o calidad diferente de productos o servicios; o</li>
            <li>Sugerir que pudiera recibir un precio o tarifa diferente por productos o servicios o un nivel o calidad diferente de productos o servicios.</li>
          </ul>
          <h2>Información de contacto</h2>
          <p>Si tiene cualquier pregunta o comentario sobre este Aviso de Privacidad, cómo recopilamos y usamos su información personal, sus opciones y derechos con respecto a dicho uso o si desea ejercer sus derechos, comuníquese con nosotros por las vías siguientes:</p>
          <div>Teléfono: 844-455-3089</div>
          <div>Correo electrónico: privacy@predictivestrategies.com </div>
          <div>Dirección física: 39400 Woodward, Suite 190, Bloomfield Hills, MI 48304</div>
        </div>)}
      </div>
    </div>
  );
};